













import { Component, Prop, Vue } from "vue-property-decorator";

import ValidUtil from "@/shared/utils/ValidUtil";
import NetworkIntegrationShortResponseModel from "@/settings/models/networks-management/NetworkIntegrationShortResponseModel";

@Component
export default class ChildNetworksForm extends Vue {
  @Prop() value!: Array<number>;
  @Prop() networkIntegrationId!: number;

  readonly requiredRule = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];

  get childNetworksIds(): Array<number> {
    return this.$store.state.networksManagementStore.childNetworks.map(
      ({ id }: NetworkIntegrationShortResponseModel) => id
    );
  }

  get items(): Array<NetworkIntegrationShortResponseModel> {
    return this.$store.state.networksManagementStore.shortNetworkIntegrations.flatMap(
      ({ id, networkName }: NetworkIntegrationShortResponseModel) =>
        this.childNetworksIds.includes(id)
          ? []
          : [{ value: id, text: networkName }]
    );
  }

  get networkIds(): Array<number> {
    return this.value;
  }

  set networkIds(value: Array<number>) {
    this.$emit("input", value);
  }
}
