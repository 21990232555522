






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import EntityCard from "@/settings/components/networks-management/EntityCard.vue";
import ChildNetworksTable from "@/settings/components/networks-management/child-networks/ChildNetworksTable.vue";
import ChildNetworksForm from "@/settings/components/networks-management/child-networks/ChildNetworksForm.vue";
import NetworkIntegrationResponseModel, {
  NetworkType,
} from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import NetworkIntegrationShortResponseModel from "@/settings/models/networks-management/NetworkIntegrationShortResponseModel";
import { NetworkIntegrationFilterType } from "@/settings/models/networks-management/NetworkIntegrationFilterType";

@Component({
  components: {
    EntityCard,
    ChildNetworksTable,
    ChildNetworksForm,
  },
})
export default class ChildNetworksView extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  visible = false;
  localValue: Array<number> = [];

  get savingInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingChildNetworkInProgress;
  }

  get childNetworks(): Array<NetworkIntegrationShortResponseModel> {
    return this.$store.state.networksManagementStore.childNetworks;
  }

  @Watch("visible")
  watchVisible(value: boolean) {
    if (value) {
      this.$store.dispatch("loadShortNetworkIntegrations", {
        networkType: NetworkType.REVENUE,
        filter: NetworkIntegrationFilterType.WITHOUT_PARENT,
      });
    }
  }

  async created() {
    await this.$store.dispatch("loadChildNetworks", this.networkIntegration.id);
  }

  handleChangeVisible(value: boolean) {
    this.visible = value;

    if (!value) {
      this.localValue = [];
    }
  }

  async handleLinkChildNetwork() {
    await this.$store.dispatch("linkChildNetwork", {
      payload: {
        childrenNetworkIntegrationIds: this.localValue,
      },
      networkIntegrationId: this.networkIntegration.id,
    });

    this.$store.dispatch("loadChildNetworks", this.networkIntegration.id);
    this.handleChangeVisible(false);
  }
}
