
























import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import NetworkIntegrationShortResponseModel from "@/settings/models/networks-management/NetworkIntegrationShortResponseModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import { AppSection } from "@/shared/models";

@Component
export default class ChildNetworksTable extends mixins(AppSectionAccessMixin) {
  @Prop() networkIntegrationId!: number;

  get appSection(): AppSection {
    return AppSection.NETWORKS_MANAGEMENT;
  }

  get headers() {
    return [
      {
        text: this.$lang("appAdmin.networksManagement.childNetworks.name"),
        value: "networkName",
      },
      ...(this.hasDeleteAccess
        ? [
            {
              text: this.$lang("shared.actions"),
              align: "end",
              value: "actions",
              width: "10%",
              sortable: false,
            },
          ]
        : []),
    ];
  }

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore.childNetworksIsLoading;
  }

  get childNetworks(): Array<NetworkIntegrationShortResponseModel> {
    return this.$store.state.networksManagementStore.childNetworks;
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get deletingChildNetwork(): number | null {
    return this.$store.state.networksManagementStore.deletingChildNetwork;
  }

  routeToChildNetwork(item: NetworkIntegrationShortResponseModel) {
    const route = this.$router.resolve({
      name: "networkIntegration",
      params: {
        id: this.applicationId,
        networkId: String(item.networkId),
        networkIntegrationId: String(item.id),
      },
    });

    window.open(route.href, "_blank");
  }

  async deleteItem({ id }: NetworkIntegrationShortResponseModel) {
    await this.$store.dispatch("deleteChildNetwork", { childNetworkId: id });
    this.$store.dispatch("loadChildNetworks", this.networkIntegrationId);
  }
}
